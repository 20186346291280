<template>
    <div class="Product">
        <div class="banner">
            <img src="./uploads/banner3.jpg" alt="">
        </div>

        <!-- 首页功能选项 -->
        <div class="tag wapper" v-if="showTag">
            <div class="breadcrumb wapper">
                <ul>
                    <li><a href="javaScript:;" class="home" @click="toHome">首页</a></li>
                    <li><a href="#" class="home">></a></li>
                    <li><a href="javaScript:;">产品介绍</a></li>
                </ul>
            </div>
            <ul class="nav">
                <li v-for="(item, index) in itamArr" :key="index" @click="toPage(index)">
                    <span></span>
                    <div class="title">{{item}}</div>
                </li>
            </ul>
        </div>
        
        <router-view v-else class="wapper" style="margin-bottom:10px"></router-view>
    </div>
</template>

<script>
export default {
    // props: ['showTag'],
    data() {
        return {
            showTag: true,
            itamArr: [],
            imgUrl: ''
        }
    },
    methods: {
        toHome() {
            this.$router.replace('/Home')
        },

        toPage(index) {
            this.showTag = false
            if(index === 0) {
                this.$router.replace('/Product/Goods')
            }else if(index === 1) {
                this.$router.replace('/Product/Order')
            }else if(index === 2) {
                this.$router.replace('/Product/Data')
            }else if(index === 3) {
                this.$router.replace('/Product/Finance')
            }else if(index === 4) {
                this.$router.replace('/Product/Auto')
            }else if(index === 5) {
                this.$router.replace('/Product/Set')
            }
        },

        async getProductData() {
            const { code,data } = await this.$http.get('/product')
            if(code === 200) {
                this.imgUrl = data.imgUrl
                this.itamArr = data.itamArr
            }
        },

    },
    mounted() {
        this.getProductData()
    },

    watch: {
        $route: {
			immediate: true,
			handler(newValue, oldValue) {
                if(newValue.path === '/Product') {
                    this.showTag = true
                }
			},
			deep:true
		}
    }
};
</script>

<style lang="less" scoped>
.Product {
    .banner {
        img {
            width: 100%;
        }
    }
    
    .tag {
        .breadcrumb {
            padding: 30px 20px;
            margin: 20px auto 40px;
            border-bottom: 1px solid rgba(0,0,0,.06);
            ul {
                display: flex;
                li {
                    margin-right: 8px;
                    .home {
                        color: rgba(0,0,0,.45);
                    }
                }
            }
        }
        .nav {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 95%;
            margin: 60px auto 40px;
            li {
                font-size: 18px;
                cursor: pointer;
                width: 26%;
                height: 95px;
                text-align: center;
                margin-bottom: 40px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                &:hover {
                    border-color: transparent;
                    box-shadow: 0 5px 15px 2px rgba(0,0,0,.5);
                }
                &:nth-child(1) {
                    background-color: #1f74ad;
                    .title {
                        color: #fff;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/goods.png");
                        background-size: cover;
                    }
                }

                &:nth-child(1):hover {
                    background-color: #1f74ad;
                    .title {
                        color: #000;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/goodsH.png");
                        background-size: cover;
                    }
                }

                &:nth-child(2) {
                    background-color: #3498db;
                    .title {
                        color: #fff;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/order.png");
                        background-size: cover;
                    }
                }

                &:nth-child(2):hover {
                    background-color: #3498db;
                    .title {
                        color: #000;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/orderH.png");
                        background-size: cover;
                    }
                }

                &:nth-child(3) {
                    background-color: #1aa3aa;
                    .title {
                        color: #fff;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/data.png");
                        background-size: cover;
                    }
                }

                &:nth-child(3):hover {
                    background-color: #1aa3aa;
                    .title {
                        color: #000;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/dataH.png");
                        background-size: cover;
                    }
                }

                &:nth-child(4) {
                    background-color: #69a35b;
                    .title {
                        color: #fff;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/finance.png");
                        background-size: cover;
                    }
                }

                &:nth-child(4):hover {
                    background-color: #69a35b;
                    .title {
                        color: #000;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/financeH.png");
                        background-size: cover;
                    }
                }

                &:nth-child(5) {
                    background-color: #9bbb59;
                    .title {
                        color: #fff;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/auto.png");
                        background-size: cover;
                    }
                }

                &:nth-child(5):hover {
                    background-color: #9bbb59;
                    .title {
                        color: #000;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/autoH.png");
                        background-size: cover;
                    }
                }

                &:nth-child(6) {
                    background-color: #ffc000;
                    .title {
                        color: #fff;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/set.png");
                        background-size: cover;
                    }
                }

                &:nth-child(6):hover {
                    background-color: #ffc000;
                    .title {
                        color: #000;
                    }
                    span {
                        width: 35px;
                        height: 35px;
                        background-image: url("./uploads/setH.png");
                        background-size: cover;
                    }
                }
            }
    
        }
    }    
}
</style>